var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-6",attrs:{"fluid":""}},[_c('v-card',[_c('v-card-title',{staticClass:"px-6 card-header white--text"},[_c('v-icon',{staticClass:"mr-3 white--text"},[_vm._v("mdi-account-eye")]),_vm._v(" "),_c('h3',[_vm._v("User Log List")])],1),_c('v-row',{staticClass:"px-8 pt-4"},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"From Date","prepend-icon":"mdi-calendar-start","readonly":"","outlined":"","dense":""},model:{value:(_vm.fdate),callback:function ($$v) {_vm.fdate=$$v},expression:"fdate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.fmenu),callback:function ($$v) {_vm.fmenu=$$v},expression:"fmenu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.fmenu = false},"change":function($event){return _vm.getAllAPILog()}},model:{value:(_vm.fdate),callback:function ($$v) {_vm.fdate=$$v},expression:"fdate"}})],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"To Date","prepend-icon":"mdi-calendar-end","readonly":"","outlined":"","dense":""},model:{value:(_vm.tdate),callback:function ($$v) {_vm.tdate=$$v},expression:"tdate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.tmenu),callback:function ($$v) {_vm.tmenu=$$v},expression:"tmenu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.tmenu = false},"change":function($event){return _vm.getAllAPILog()}},model:{value:(_vm.tdate),callback:function ($$v) {_vm.tdate=$$v},expression:"tdate"}})],1)],1),_c('v-col',{attrs:{"cols":"2"}}),_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{attrs:{"color":"excel","elevation":"2","disabled":_vm.body_list == null},on:{"click":function($event){return _vm.exportReport('excel')}}},[_c('v-icon',{attrs:{"color":"white","center":"","dark":""}},[_vm._v(" mdi-microsoft-excel ")])],1)],1),_c('v-col',{attrs:{"cols":"3","align":"right"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","outlined":"","hide-details":"","dense":""},model:{value:(_vm.searchDt),callback:function ($$v) {_vm.searchDt=$$v},expression:"searchDt"}})],1)],1),_c('v-data-table',{staticClass:"elevation-1 px-6 headerDtSarawak",attrs:{"headers":_vm.thead_list,"items":_vm.body_list,"search":_vm.searchDt,"loading":_vm.loadingDt,"items-per-page":5},scopedSlots:_vm._u([{key:"item.STATION_ID",fn:function(ref){
var item = ref.item;
return [[_c('span',{staticClass:"primary--text text-decoration-underline",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.openListStationParams(item.STATION_ID)}}},[_vm._v(" "+_vm._s(item.STATION_ID)+" ")])]]}}])})],1),_c('v-dialog',{attrs:{"max-width":"80%","persistent":"","scrollable":""},model:{value:(_vm.dialogParamsList),callback:function ($$v) {_vm.dialogParamsList=$$v},expression:"dialogParamsList"}},[_c('v-card',{staticClass:"card-bg"},[_c('v-card-title',{staticClass:"primary white--text justify-center dark"},[_c('span',{staticClass:"text-h5 white--text"},[_vm._v("Parameter List")]),_c('v-spacer'),_c('v-icon',{staticClass:"white--text",staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.dialogParamsList = false}}},[_vm._v(" mdi-close-thick ")])],1),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',[_c('h5',{staticClass:"text-h5 black--text"},[_vm._v(_vm._s(_vm.selectedStationID))])])],1),_c('v-data-table',{staticClass:"elevation-1 headerDtSarawak my-4",attrs:{"headers":_vm.theadParams,"items":_vm.tbodyParams,"loading":_vm.loadingTableParams,"items-per-page":10}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }